import { CleanDataSettings } from '../../types/CleanDataSettings'

export const cleanDataSettingsToRequestFormat = (
  settings: CleanDataSettings
) => {
  return {
    dateRange: {
      // unlike other settings, this one has no shouldApply property as it should always be applied. If these dateRange.startDate and/or dateRange.endDate are not provided and valid, return an error.
      startDate: settings.startDate, // timestamp milliseconds (you may ignore hh:ss:mm)
      endDate: settings.endDate, // timestamp millisecond (you may ignore hh:ss:mm)
    },
    periodOfYear: {
      shouldApply: settings.periodOfYear, // if false, do not apply period of year settings (even if they are set)
      selectedPeriods: settings.periods, // Array of periods (spring / summer / autumn / winter / custom). If periodOfYear.shouldApply is true and this array is empty or null, then return an error. If this array does not include 'custom', then ignore the periodOfYear.customPeriod settings.
      // if 'custom' appears in the periodOfYear.selectedPeriods array, but customPeriod.startDDMM and/or customPeriod.endDDMM are not provided and valid, return an error.
      customPeriod: {
        // this may be either null or an object with the below properties
        startDDMM: settings.customStartDateText,
        endDDMM: settings.customEndDateText,
      },
    },
    timeOfDay: {
      shouldApply: settings.timeOfDay, // if false, do not apply time of day settings (even if they are set). if true, but timeOfDay.startTime and/or timeOfDay.endTime are not provided and valid, then return an error.
      startTime: settings.startTime, // timestamp milliseconds (you may ignore day,month,year)
      endTime: settings.endTime, // timestamp milliseconds (you may ignore day,month,year)
    },
    hourlyMean: {
      shouldApply: settings.hourlyMean, // if false, do not apply hourly mean
    },
    thresholdFilter: {
      shouldApply: settings.threshold, // if false, do not apply threshold filter settings (even if they are set)
      thresholdValue: settings.thresholdValue, // if thresholdFilter.shouldApply is true, then this value must be provided and valid, otherwise return an error.
      thresholdType: settings.thresholdType, // this must be either 'Above' or 'Below', (or an empty string if shouldApply === false) otherwise return an error.
    },
    ratio: {
      shouldApply: settings.ratioFilter, // if false, do not apply ratio settings (even if they are set)
      ratio: settings.ratio, // if ratio.shouldApply is true, then this value must be provided and valid, otherwise return an error.
      threshold: settings.ratioThreshold, // if ratio.shouldApply is true, then this value must be provided and valid, otherwise return an error.
      step: settings.ratioStep, // if ratio.shouldApply is true, then this value must be provided and valid, otherwise return an error.
    },
    nMaxima: {
      shouldApply: settings.nMaxima, // if false, do not apply nMaxima settings (even if they are set)
      value: settings.nMaximaValue, // if nMaxima.shouldApply is true, then this value must be provided and valid, otherwise return an error.
    },
    removeVariableWindDirection: {
      shouldApply: settings.removeVariableWindDirection,
    },
    removeThunderstorms: {
      shouldApply: settings.removeThunderstorms,
    },
  }
}
