import { Typography } from '@mui/material'
import LinkButton from '../../../../ui/buttons/LinkButton'

type Props = {}

const NoStationSelected = (props: Props) => {
  return (
    <>
      <Typography>
        You must select a station before you can clean and review the data.
        Please return to the "Select a weather station" step, select the station
        you wish to use from the map, and then click "Save and Continue".
      </Typography>
      <LinkButton to="../select-station">Select weather station</LinkButton>
    </>
  )
}

export default NoStationSelected
