import Navbar from './ui/layout/Navbar'
import { Routes, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import DateAdapter from '@mui/lab/AdapterLuxon'

// auth imports
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from './auth/authConfig'

// Import Pages
import Home from './routes/Home'
import RecentAnalyses from './routes/Home/RecentAnalyses'
import NewProject from './routes/Home/NewProject'
import Assessment from './routes/Assessment'
import Project from './routes/Project'
import SelectStation from './routes/Assessment/SelectStation'
import RedirectToFirstIncompleteStep from './routes/Assessment/RedirectToFirstIncompleteStep'
import CleanAndReview from './routes/Assessment/CleanAndReview'
import { LocalizationProvider } from '@mui/lab'

const queryClient = new QueryClient()

function App() {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <LocalizationProvider dateAdapter={DateAdapter} locale="en-GB">
        <QueryClientProvider client={queryClient}>
          <arc-container theme={'light'}>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />}>
                <Route index element={<RecentAnalyses />} />
                <Route path="new-project" element={<NewProject />} />
              </Route>
              <Route path="/assessment/:assessmentId" element={<Assessment />}>
                <Route path="select-station" element={<SelectStation />} />
                <Route path="clean-and-review" element={<CleanAndReview />} />
                <Route path="analyse-data" element={null} />
                <Route index element={<RedirectToFirstIncompleteStep />} />
              </Route>
              <Route path="/project/:projectId" element={<Project />} />
            </Routes>
          </arc-container>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </LocalizationProvider>
    </MsalAuthenticationTemplate>
  )
}

export default App
