import { useCallback, useEffect, useRef } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import Button from '../../../../ui/buttons/Button'
import type { Assessment } from '../../../../types/Assessment'
import FormSectionPanel from '../../../../ui/forms/FormSectionPanel'
import FormFieldSet from '../../../../ui/forms/FormFieldset'
import DateField from '../../../../ui/forms/DateField'
import SelectField from '../../../../ui/forms/SelectField'
import DayAndMonthField from '../../../../ui/forms/DayAndMonthField'
import { tooltips } from '../const'
import TimeField from '../../../../ui/forms/TimeField'
import NumberField from '../../../../ui/forms/NumberField'
import type { CleanDataSettings } from '../../../../types/CleanDataSettings'
import { useAppDispatch } from '../../../../store/hooks'
import { setCleanDataFormIsDirty } from '../../../../features/assessments/assessmentsSlice'
import { UseMutationResult, useQueryClient } from 'react-query'
import { formatWeatherData } from '../../../../lib/dataTransformers/weatherTransform'
import { Retry } from '../../../../ui/alerts/Retry'

type Props = {
  assessment: Assessment
  applySettingsMutation: UseMutationResult<
    ReturnType<typeof formatWeatherData>,
    unknown,
    CleanDataSettings,
    unknown
  >
  saveSettingsMutation: UseMutationResult<
    Assessment,
    unknown,
    CleanDataSettings,
    unknown
  >
}

const CleanDataSettingsForm = ({
  assessment,
  applySettingsMutation,
  saveSettingsMutation,
}: Props) => {
  const minDate = DateTime.fromMillis(assessment.station?.begin ?? 0)
  const maxDate = DateTime.fromMillis(assessment.station?.end ?? 0)
  const defaultStartDate = maxDate.minus({ years: 10 }).startOf('year')

  const minDateString = minDate.toFormat('MM/yy')
  const maxDateString = maxDate.toFormat('MM/yy')

  const dispatch = useAppDispatch()

  const formRef = useRef<HTMLFormElement>(null)
  const formMethods = useForm<CleanDataSettings>({
    defaultValues: assessment.cleanDataSettings ?? {
      startDate: defaultStartDate.toUnixInteger() * 1000,
      endDate: maxDate.toUnixInteger() * 1000,
      periodOfYear: false,
      periods: [],
      customStartDateText: '',
      customEndDateText: '',
      timeOfDay: false,
      startTime: null,
      endTime: null,
      hourlyMean: false,
      threshold: false,
      thresholdValue: null,
      thresholdType: '',
      ratioFilter: false,
      ratio: 2,
      ratioThreshold: 10,
      ratioStep: 2,
      nMaxima: false,
      nMaximaValue: null,
      removeVariableWindDirection: false,
      removeThunderstorms: false,
    },
  })
  const {
    register,
    watch,
    control,
    handleSubmit,
    getValues,
    formState: { isDirty, submitCount, isSubmitSuccessful },
    reset,
  } = formMethods

  useEffect(() => {
    if (!isDirty || (isSubmitSuccessful && submitCount > 0)) {
      dispatch(setCleanDataFormIsDirty(isDirty))
    }
  }, [isSubmitSuccessful, submitCount, isDirty, dispatch])

  const [periodOfYear, periods, timeOfDay, threshold, ratioFilter, nMaxima] =
    watch([
      'periodOfYear',
      'periods',
      'timeOfDay',
      'threshold',
      'ratioFilter',
      'nMaxima',
    ])

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const saveSettings = useCallback(() => {
    const cleanDataSettings = getValues()
    saveSettingsMutation.mutate(cleanDataSettings, {
      onSuccess: (assessment) => {
        queryClient.setQueryData(['assessment', assessment.id], assessment)
        navigate('../analyse-data')
      },
    })
  }, [getValues, navigate, saveSettingsMutation, queryClient])

  return (
    <>
      <Stack overflow="hidden">
        <Box
          display="flex"
          justifyContent={'space-between'}
          alignItems="center"
          p={2}
        >
          <Typography
            variant="subtitle2"
            component="h1"
            color="rgb(var(--arc-color-primary))"
          >
            Clean data
          </Typography>
          {
            <Button
              onClick={() => formRef.current?.dispatchEvent(submitEvent)}
              arcBtnProps={{ color: 'error' }}
              disabled={
                !(isDirty || submitCount === 0 || !isSubmitSuccessful) ||
                applySettingsMutation.isLoading
              }
              loading={applySettingsMutation.isLoading}
            >
              {submitCount === 0
                ? 'Download & Apply Settings'
                : 'Apply Changes'}
            </Button>
          }
        </Box>
        <FormProvider {...formMethods}>
          <form
            ref={formRef}
            onSubmit={handleSubmit(
              async (data) => {
                applySettingsMutation.mutate(data, {
                  onSuccess: () => {
                    console.log('onSuccess was called')
                  },
                })
                reset(data, {
                  keepValues: true,
                  keepErrors: true,
                  keepSubmitCount: true,
                })
              },
              (errors) => console.error(errors)
            )}
            style={{ overflow: 'auto' }}
            noValidate
          >
            <Stack gap={3}>
              <FormSectionPanel title="Selections">
                <FormFieldSet
                  name="Date range"
                  tooltipContent={tooltips.dateRange}
                >
                  <DateField
                    name="startDate"
                    label="Start"
                    control={control}
                    defaultValue={defaultStartDate.toUnixInteger()}
                    rules={{ required: 'Required' }}
                    muiDatePickerProps={{
                      minDate: minDate,
                      maxDate: maxDate,
                    }}
                    helperText={`Earliest: ${minDateString}`}
                    required
                  />
                  <DateField
                    name="endDate"
                    label="End"
                    control={control}
                    defaultValue={maxDate.toUnixInteger()}
                    rules={{ required: 'Required' }}
                    muiDatePickerProps={{
                      minDate: minDate,
                      maxDate: maxDate,
                    }}
                    helperText={`Latest: ${maxDateString}`}
                    required
                  />
                </FormFieldSet>
                <FormFieldSet
                  name="Period of the year"
                  tooltipContent={tooltips.periodOfYear}
                  checkbox={{
                    controlProps: register('periodOfYear'),
                    label: 'Specify period of the year',
                  }}
                >
                  <SelectField
                    name="periods"
                    label="Presets"
                    control={control}
                    defaultValue={[]}
                    rules={{ required: periodOfYear && 'Required' }}
                    active={periodOfYear}
                    options={['Spring', 'Summer', 'Autumn', 'Winter', 'Custom']}
                  />

                  <Box display="flex">
                    <DayAndMonthField
                      name="custom date start"
                      label="From"
                      active={periodOfYear && periods.includes('Custom')}
                      fieldId="customStartDateText"
                    />
                    <DayAndMonthField
                      name="custom date end"
                      label="To"
                      active={periodOfYear && periods.includes('Custom')}
                      fieldId="customEndDateText"
                    />
                  </Box>
                </FormFieldSet>
                <FormFieldSet
                  name="Time of the day"
                  tooltipContent={tooltips.timeOfDay}
                  checkbox={{
                    controlProps: register('timeOfDay'),
                    label: 'Specify time of the day',
                  }}
                >
                  <TimeField
                    name="startTime"
                    label="Start"
                    control={control}
                    rules={{
                      required: timeOfDay ? 'Required' : false,
                      validate: (val: DateTime) => {
                        if (!timeOfDay) return true
                        return val.isValid
                      },
                    }}
                    active={timeOfDay}
                  />
                  <TimeField
                    name="endTime"
                    label="End"
                    control={control}
                    rules={{
                      required: timeOfDay ? 'Required' : false,
                      validate: (val: DateTime) => {
                        if (!timeOfDay) return true
                        return val.isValid
                      },
                    }}
                    active={timeOfDay}
                  />
                </FormFieldSet>
                <FormFieldSet
                  name="Hourly mean"
                  tooltipContent={tooltips.hourlyMean}
                  checkbox={{
                    controlProps: register('hourlyMean'),
                    label: 'Should calculate hourly mean',
                  }}
                />
              </FormSectionPanel>
              <FormSectionPanel title="Remove Outliers">
                <FormFieldSet
                  name="Threshold"
                  tooltipContent={tooltips.threshold}
                  checkbox={{
                    controlProps: register('threshold'),
                    label: 'Threshold filter',
                  }}
                >
                  <Stack>
                    <Typography mb={2}>Wind speed</Typography>
                    <Box display="flex" justifyContent="space-between" gap={1}>
                      <NumberField
                        name="threshold value"
                        label="Value"
                        fieldId="thresholdValue"
                        active={threshold}
                        registerOptions={{
                          required: threshold
                            ? 'You must specify a threshold value'
                            : false,
                          validate: (val) => !Number.isNaN(val),
                        }}
                      />
                      <SelectField
                        name="thresholdType"
                        label="Type"
                        control={control}
                        defaultValue={''}
                        rules={{ required: threshold && 'Required' }}
                        active={threshold}
                        muiSelectProps={{ multiple: false }}
                        options={['Above', 'Below']}
                      />
                    </Box>
                  </Stack>
                </FormFieldSet>

                <FormFieldSet
                  name="Ratio"
                  tooltipContent={tooltips.ratio}
                  checkbox={{
                    controlProps: register('ratioFilter'),
                    label: 'Ratio filter',
                  }}
                >
                  <Stack>
                    <Typography mb={2}>Wind speed</Typography>
                    <Box display="flex" justifyContent="space-between" gap={1}>
                      <NumberField
                        name="ratio"
                        label="Ratio"
                        fieldId="ratio"
                        inputProps={{
                          min: 1.01,
                          step: 0.01,
                        }}
                        registerOptions={{
                          value: 2,
                          min: 1.01,
                          required: ratioFilter
                            ? 'You must specify a ratio value'
                            : false,
                        }}
                        active={ratioFilter}
                      />
                      <NumberField
                        name="ratioThreshold"
                        label="Threshold"
                        fieldId="ratioThreshold"
                        inputProps={{
                          step: 0.01,
                          min: 0.01,
                        }}
                        registerOptions={{
                          value: 10,
                          min: 0.01,
                          required: ratioFilter
                            ? 'You must specify a ratio threshold value'
                            : false,
                        }}
                        active={ratioFilter}
                      />
                      <NumberField
                        name="step"
                        label="Step"
                        fieldId="ratioStep"
                        inputProps={{
                          step: 1,
                          min: 1,
                        }}
                        registerOptions={{
                          value: 2,
                          min: 1,
                          required: ratioFilter
                            ? 'You must specify a ratio step value'
                            : false,
                          validate: (val) => Number.isInteger(parseFloat(val)),
                        }}
                        active={ratioFilter}
                      />
                    </Box>
                  </Stack>
                </FormFieldSet>
                <FormFieldSet
                  name="N-Maxima"
                  tooltipContent={tooltips.nMaxima}
                  checkbox={{
                    controlProps: register('nMaxima'),
                    label: 'n-maxima filter',
                  }}
                >
                  <Stack>
                    <Typography mb={2}>Wind speed</Typography>
                    <Box display="flex" justifyContent="space-between" gap={1}>
                      <NumberField
                        name="nMaxima"
                        label="Number"
                        fieldId="nMaximaValue"
                        inputProps={{
                          step: 1,
                          min: 1,
                        }}
                        registerOptions={{
                          required: nMaxima
                            ? 'You must specify an N-Maxima value'
                            : false,
                          validate: (val) => {
                            if (!nMaxima) return true
                            return Number.isInteger(parseFloat(val)) && val >= 1
                          },
                        }}
                        active={nMaxima}
                        muiTextFieldProps={{ fullWidth: true }}
                      />
                    </Box>
                  </Stack>
                </FormFieldSet>
                <FormFieldSet
                  name="Remove variable wind direction"
                  tooltipContent={tooltips.removeVariableWindDirection}
                  checkbox={{
                    controlProps: register('removeVariableWindDirection'),
                    label: 'remove variable wind direction',
                  }}
                />
                <FormFieldSet
                  name="Remove thunderstorms"
                  tooltipContent={tooltips.removeThunderstorms}
                  checkbox={{
                    controlProps: register('removeThunderstorms'),
                    label: 'remove thunderstorms',
                  }}
                />
              </FormSectionPanel>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
      {saveSettingsMutation.isError && (
        <Box mt={2}>
          <Retry retry={saveSettings} />
        </Box>
      )}
      <Box m={2} display="flex" justifyContent="space-between" gap={2}>
        <Button
          onClick={() => {
            navigate('../select-station')
          }}
        >
          Back
        </Button>
        <Button
          onClick={saveSettings}
          loading={saveSettingsMutation.isLoading}
          disabled={applySettingsMutation.isLoading}
          color="primary"
        >
          Save & Continue
        </Button>
      </Box>
    </>
  )
}

const submitEvent = new Event('submit', { bubbles: true, cancelable: true })

export default CleanDataSettingsForm
